<template>
    <div class="modal-content">
        <p @click="closeModal()" class="close"> &times;</p>
        <img src="../../assets/cert/certLabBarragan.jpg">
    </div>
</template>

<script>


export default {
    name: 'BarraganModal',
    data(){
        return{
            showmodal:false,
            modalopc:""
        }
    },
    methods: {
         closeModal:function(){
             this.$emit('listenModal', this.showmodal)
         }
    }
}
</script>

<style scoped>

.modal-content {
  background-color: #fefefe;
  margin: -8% auto; /* 15% from the top and centered */
  padding: 1.5vw;
  border: 1px solid #888;
  width: 48%; /* Could be more or less, depending on screen size */
}
.modal-content img{
    width: 100%;
}
.close {
  color: #aaa;
  float: right;
  font-size: 1.7;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.ModalLaason{    
    position: absolute;
    margin: -49vw 19vw;
}

</style>