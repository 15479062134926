<template>
  <div>
    <HeaderB />
    <div class="content">
      <div class="Bloque1">
        <div class="B1F1">
          <div class="B1F1C1">
            <div class="B1F1C1_C1">
              <img src="../assets/logo1_1.png" />
            </div>
            <div class="B1F1C1_C2">
              <p>LABORATORIO CLÍNICO BARRAGAN</p>
            </div>
          </div>

          <div class="B1F1C2">
            <div class="B1F1C2_C1">
              <ul>
                <li @click="cambiarOpcion('Home')" :class="{ selector: this.opcion == 'Home' }">
                  Inicio
                </li>
                <li @click="cambiarOpcion('Nosotros')" :class="{ selector: this.opcion == 'Nosotros' }">
                  <a href="#nosotros">Nosotros</a>
                </li>
                <li @click="cambiarOpcion('Servicios')" :class="{ selector: this.opcion == 'Servicios' }">
                  <a href="/barragan/servicios">Servicios</a>
                </li>
                <li @click="cambiarOpcion('Certificaciones')" :class="{ selector: this.opcion == 'Certificaciones' }">
                  <a href="#certificaciones">Certificaciones</a>
                </li>
                <li @click="cambiarOpcion('Contacto')" :class="{ selector: this.opcion == 'Contacto' }">
                  <a href="#contacto">Contacto</a>
                </li>
                <li @click="cambiarOpcion('Privacidad')" :class="{ selector: this.opcion == 'Privacidad' }">
                  <a href="/barragan/politica">Aviso Privacidad</a>
                </li>
                <li :class="{ selector: this.opcion == 'Quejas' }">
                  <a href="#" @click="cambiarOpcion('Quejas')">Quejas</a>

                  <div class="wrapper" :class="{ 'extended': opcion == 'Quejas' }">
                    <div>
                      <h2>¿Tienes alguna queja o sugerencia para nuestro servicio? </h2>
                      <a href="mailto:dirección@laason.mx">Envíanos un mensaje</a>
                      <ol>
                        <li>Envíanos un mensaje por medio de este botón o al correo <a
                            href="mailto:dirección@laason.mx">dirección@laason.mx</a>
                        </li>
                        <li>Te confirmaremos de recibida tu queja o sugerencia, si es una queja recibe y
                          analiza las causas que la originaron si procede o no en un plazo máximo de
                          10 días hábiles.</li>
                        <li>Se tomaran las acciones pertinentes y nos comunicaremos contigo.</li>
                      </ol>
                      <h3>Muchas gracias por ser parte de nuestra mejora continua.</h3>

                      <button @click="cambiarOpcion('Home')">Salir</button>
                    </div>
                  </div>
                </li>
                <li class="ClienteButton" :class="{ selector: this.opcion == 'Aclient' }">
                  <a href="/login">Acceso a Clientes<img src="../assets/iconUser.svg" /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <swiper ref="mySwiper" :options="swiperOption">
          <swiper-slide>
            <div class="B1F2">
              <div class="B1F2C1">
                <div class="B1F2C1_F1">
                  <p>
                    Filtros sanitarios
                    <span>para la manipulación de alimentos</span>
                  </p>
                </div>
                <div class="B1F2C1_F2">
                  <p>
                    Nuestros filtros están diseñados, construidos y certificados
                    según las normas sanitarias.
                  </p>
                </div>
                <a href="https://laason.mx/barragan/servicios">Ver más</a>
              </div>
              <img class="B1F2C2" src="../assets/Rectangle_22.png" />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="B1F2">
              <div class="B1F2C1">
                <div class="B1F2C1_F1S2">
                  <p><span>Nuestros</span> Servicios</p>
                </div>
                <div class="B1F2C1_F2S2">
                  <p>
                    Contamos con una amplia de gama de estudios tanto de rutina
                    como de alta especialidad.
                  </p>
                </div>
                <a href="/barragan/servicios">Ver más</a>
              </div>
              <img class="B1F2C2_S2" src="../assets/servicios3_1.png" />
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <div class="bloquedos">
        <div class="displacementPoint" slot="pagination"></div>
        <div class="filauno-bd">
          <h6>Servicios</h6>
          <h2>Servicios que ofrecemos</h2>
        </div>
        <div class="filados-bd">
          <div class="coluno-bd">
            <div class="bloserv">
              <img class="C1IMG1" src="../assets/icocuatro.png" alt="" />
              <div class="blotxtserv">
                <p class="C1T1">Análisis clínicos</p>
                <a href="https://laason.mx/barragan/servicios">Ver más</a>
              </div>
            </div>
            <div class="bloserv">
              <img class="C1IMG2" src="../assets/Group_2.png" alt="" />
              <div class="blotxtserv">
                <p class="C1T2">Pruebas de Antidoping</p>
                <a href="https://laason.mx/barragan/servicios">Ver más</a>
              </div>
            </div>
          </div>

          <div class="coldos-bd">
            <div class="bloserv">
              <img class="C2IMG1" src="../assets/Frame_4.png" alt="" />
              <div class="blotxtserv">
                <p class="C2T1">Pruebas Covid</p>
                <a href="https://laason.mx/barragan/servicios">Ver más</a>
              </div>
            </div>
            <div class="bloserv">
              <img class="C2IMG2" src="../assets/Frame_3.png" alt="" />
              <div class="blotxtserv">
                <p class="C2T2">
                  Filtros sanitarios para la manipulación de alimentos
                </p>
                <a href="https://laason.mx/barragan/servicios">Ver más</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="nosotros" class="Bloquetres">
        <div class="B3F1">
          <h6>Sobre nosotros</h6>
          <h2>Estamos a tu servicio</h2>
        </div>

        <div class="B3F2">
          <div class="B3F2C1">
            <h3>Misión</h3>
            <p>
              Proveer a sus clientes de una atención profesional, de calidad y
              calidez, utilizando técnicas confiables en la cadena de
              diagnóstico clínico para así, mejorar la calidad de vida de
              nuestros clientes.
            </p>
          </div>
          <div class="GreyLine"></div>
          <div class="B3F2C2">
            <h3>Visión</h3>
            <p>
              Proyectamos un crecimiento de nuestra empresa, acorde a nuestra
              importante trayectoria y lo que hemos consolidado en años de
              funcionamiento. Nuestro objetivo es constituirnos en el
              Laboratorio Clínico preferido, reconocido por su excelencia en
              servicio y calidad.
            </p>
          </div>
          <div class="GreyLine"></div>
          <div class="B3F2C3">
            <h3>Valores</h3>
            <ul>
              <li>Calidad</li>
              <li>Responsabilidad</li>
              <li>Honestidad</li>
              <li>Respeto</li>
              <li>Discreción</li>
              <li>Amabilidad</li>
            </ul>
          </div>
        </div>
      </div>

      <div id="certificaciones" class="Bloquecuatro">
        <div class="B4C1">
          <h6>Certificaciones</h6>
          <h3>Comprometidos con la calidad</h3>
          <div class="B4C1F3">
            <img src="../assets/Frame_Sello.png" />
            <p>Laboratorio Sistema de Gestión Certificado ISO 9001:2015</p>
          </div>
          <a @click="showmodal = !showmodal" target="_blank">Ver certificado</a>
          <div v-if="showmodal == true" class="modalss">
            <BarraganModal @listenModal="closeModal" />
          </div>
        </div>
        <div class="B4C2">
          <img src="../assets/Rectangle_24.png" />
        </div>
      </div>

      <div class="Bloque12" id="contacto">
        <div class="B12B1">
          <img src="../assets/Rectangle_25P.png" />
        </div>

        <div id="B12" class="B12_Esp">
          <div class="B12_F1">
            <p>¿Dudas? Mándanos un mensaje.</p>
          </div>
          <form @submit.prevent="enviarMail(name, phone, email, message)">
            <div class="B12_F2">
              <label for="Nombre">Nombre</label>
              <input v-model="name" name="Nombre" />
            </div>
            <div class="B12_F2">
              <label for="Telefono">Télefono</label>
              <input v-model="phone" name="Telefono" />
            </div>
            <div class="B12_F2">
              <label for="Correo">Correo</label>
              <input v-model="email" name="Correo" />
            </div>
            <div class="B12_F5">
              <label for="Mensaje">Mensaje</label>
              <textarea v-model="message" name="Mensaje"></textarea>
            </div>
            <div class="B12_F6">
              <button type="submit">Enviar</button>

              <div v-if="this.status == 'success'">
                <p class="cart_msg cart_success">{{ this.msg }}</p>
              </div>
              <div v-if="this.status == 'error'">
                <p class="cart_msg cart_error">{{ this.msg }}</p>
              </div>
            </div>
          </form>
          <div class="B12_F7">
            <p><span>Tels.</span> (662) 213.23.02 y (662) 313.06.50.</p>
            <p><span>Mail.</span> barragan@laason.mx</p>
            <p>
              <span>Dirección:</span> Garmendia N°80, Col.San Benito C.P. 83190.
              Hermosillo, Sonora.
            </p>
          </div>
          <div class="EB12_F7">
            <div class="Eb12_F1">
              <p><span>Tels.</span> (662) 213.23.02 y (662) 313.06.50.</p>
              <p><span>|</span></p>
              <p><span>Mail.</span> barragan@laason.mx</p>
            </div>
            <p>
              <span>Dirección:</span> Garmendia N°80, Col.San Benito C.P. 83190.
              Hermosillo, Sonora.
            </p>
          </div>
        </div>
      </div>
      <div class="Bloque13"></div>
      <div class="Bloque14">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3486.378370506103!2d-110.95978618472249!3d29.094508882237868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ce850526e3f02f%3A0xbbb1453832ac2b5b!2sLaboratorio%20de%20Alimentos%20y%20Aguas%20de%20Sonora%20S.A.%20de%20C.V.!5e0!3m2!1ses!2smx!4v1638914584188!5m2!1ses!2smx"
          width="100%" height="400" style="border: 0" allowfullscreen="" loading="lazy"></iframe>
      </div>
    </div>
    <FooterSec :name="this.opc" />
  </div>
</template>


<script>
import HeaderB from "./HeaderB.vue";
import FooterSec from "./FooterSec.vue";
import BarraganModal from "./modal/BarraganModal.vue";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "HomeBarragan",
  data() {
    return {
      showmodal: false,
      opc: "barragan",
      opcion: "Home",
      name: "",
      phone: "",
      email: "",
      message: "",
      status: "",
      msg: "",
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 1,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  components: {
    HeaderB,
    FooterSec,
    BarraganModal,
    Swiper,
    SwiperSlide,
  },
  computed: {
    seleccionar: function () {
      return { color: "#a94442" };
    },
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },

  created() {
    const direccion = localStorage.getItem("direccion");

    if (direccion) {
      localStorage.removeItem("direccion");
      setTimeout(() => {
        window.location.href = `#${direccion}`
      }, 600);
    }
  },
  mounted() {
    this.swiper.slideTo(0, 7, false);
  },
  methods: {
    closeModal(value) {
      this.showmodal = value;
    },
    cambiarOpcion(opcion) {
      this.opcion = opcion;
    },

    enviarMail: async function (name, phone, email, message) {
      this.msg = "";
      this.status = "";

      if (name == undefined || name == "" || name == null) {
        this.msg = "El campo nombre no es valido.";
        this.status = "error";
      } else if (phone == undefined || phone == "" || phone == null) {
        this.msg = "El campo medio no es valido.";
        this.status = "error";
      } else if (email == undefined || email == "" || email == null) {
        this.msg = "El campo correo no es valido.";
        this.status = "error";
      } else if (message == undefined || message == "" || message == null) {
        this.msg = "El campo comentarios no es valido.";
        this.status = "error";
      } else {
        let data = {
          name: name,
          phone: phone,
          email: email,
          mensaje: message,
          Tel_Empresa: "(662) 213.23.02 y (662) 313.06.50.",
          Mail_Empresa: "barragan@laason.mx",
          Direc_Empresa:
            "Garmendia N°80, Col.San Benito C.P. 83190. Hermosillo, Sonora.",
        };
        let response = await this.$store.dispatch(
          "admin/enviarMailContacto",
          data
        );
        if (response.status == "success") {
          this.status = "success";
          this.msg = "Mensaje enviado con exito.";
          this.name = "";
          (this.phone = ""), (this.email = ""), (this.message = "");
        } else {
          this.status = "error";
          this.msg =
            "Lo sentimos ha ocurrido un error al intentar entregar tu mensaje.";
        }
      }
    },
  },
};
</script>

<style scoped>
/*Estos estilos comienzan con Movil y en mediaquery estan los estilos de escritorio*/
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;1,300&display=swap");

.displacementPoint {
  text-align: center;
}

.displacementPoint span {
  margin: 0vw 0.2vw;
}

.selector {
  border: 1px solid white;
  border-radius: 3vw;
  text-align: center;
  margin: 0vw !important;
}

.Bloque1 {
  display: block;
  width: 100%;
  height: 175.36231884057972vw;
  background: linear-gradient(243.78deg, #6fba91 17.49%, #0a4ca1 99.32%);
  text-align: -webkit-center;
  padding-top: 0vw;
}

.B1F1 {
  width: 86.88405797101449vw;
  border-bottom: 1px solid white;
  padding-bottom: 4.830917874396135vw;
  margin-bottom: 6.038647342995169vw;
}

.B1F1C1 {
  display: flex;
  align-items: center;
}

.B1F1C1_C1 {
  margin-top: 4vw;
}

.B1F1C1_C1 img {
  width: 13.285024154589372vw;
  height: auto;
}

.B1F1C1_C2 {
  width: 32vw;
  height: 9vw;
  margin-left: 3.3816425120772946vw;
  padding-top: 5vw;
}

.B1F1C1_C2 p {
  color: white;
  font-size: 3.140096618357488vw;
  line-height: 4.270531400966184vw;
  font-weight: 700;
  text-align: left;
}

.B1F1C2 {
  display: none;
}

.B1F2C1 a {
  font-size: 3.140096618357488vw;
  padding: 4.1vw 29.9vw;
  border-radius: 8vw;
  margin-left: 0vw;
  color: white;
  background: linear-gradient(to left, #6fba91 100%, #76dfa6 100%);
}

img.B1F2C2 {
  width: 99.903382vw;
  height: auto;
  margin-top: 13.3vw;
}

img.B1F2C2_S2 {
  width: 99.903382vw;
  height: auto;
  margin-top: 10.6vw;
}

.B1F2C1_F1 p {
  color: #bbefe3;
  font-size: 12.077294685990339vw;
  font-weight: 800;
  margin-bottom: 6.038647342995169vw;
  line-height: 7.971014492753622vw;
}

.B1F2C1_F1 span {
  font-size: 5.5vw;
}

.B1F2C1_F1S2 p {
  width: 50vw;
  color: #bbefe3;
  font-size: 12.077294685990339vw;
  font-weight: 800;
  margin-bottom: 6.038647342995169vw;
  line-height: 7.971014492753622vw;
}

.B1F2C1_F1S2 span {
  font-size: 5.5vw;
}

.B1F2C1_F2 p {
  width: 61vw;
  color: white;
  font-size: 3.140096618357488vw;
  font-weight: 400;
  margin-bottom: 7vw;
}

.B1F2C1_F2S2 p {
  width: 80vw;
  color: white;
  font-size: 3.140096618357488vw;
  font-weight: 400;
  margin-bottom: 10vw;
}

.bloquedos {
  background-image: url("../assets/shutterstockP.png");
  background-repeat: no-repeat;
  background-position-y: 178vw;
  background-position-x: 10vw;
  background-size: 90vw;
  height: 255.79710144927535vw;
}

.filauno-bd {
  margin-left: 11.510416666666666vw;
  padding-top: 11.83574879227053vw;
}

.filauno-bd h6 {
  height: 4vw;
  font-size: 3.3816425120772946vw;
  font-weight: 500;
  line-height: 1vw;
  color: #6fba91;
}

.filauno-bd h2 {
  width: 51vw;
  height: 18vw;
  font-size: 8.454106280193237vw;
  font-weight: 800;
  line-height: 10.144927536231885vw;
  color: #565656;
}

.filados-bd {
  display: block;
  width: 100%;
  padding-top: 12vw;
  padding-left: 11.510416666666666vw;
}

.coluno-bd {
  margin-left: 0vw;
}

.bloserv {
  display: flex;
  margin-bottom: 12.560386473429952vw;
}

.blotxtserv {
  margin-left: 1.8229166666666667vw;
  padding-top: 1.1vw;
}

.C1IMG1 {
  width: 16.908212560386474vw;
  height: 17.874396135265698vw;
  margin-right: 9.66183574879227vw;
}

.C1T1 {
  width: 39vw;
  font-size: 3.864734299516908vw;
  margin-bottom: 0vw;
  font-weight: 500;
  color: #a6a6a6;
}

.blotxtserv a {
  font-size: 3.140096618357488vw;
  color: #9bd2c5;
}

.C1IMG2 {
  width: 16.835748792270532vw;
  height: 15.403381642512079vw;
  margin-right: 9.66183574879227vw;
}

.C1T2 {
  width: 37vw;
  font-size: 3.864734299516908vw;
  margin-bottom: 0vw;
  font-weight: 500;
  color: #a6a6a6;
}

.C2IMG1 {
  width: 12.80193236714976vw;
  height: 14.975845410628018vw;
  margin-right: 13.66183574879227vw;
}

.C2T1 {
  width: 38vw;
  font-size: 3.864734299516908vw;
  margin-bottom: 0vw;
  font-weight: 500;
  color: #a6a6a6;
}

.C2IMG2 {
  width: 16vw;
  height: 17.874396135265698vw;
  margin-right: 10.66183574879227vw;
}

.C2T2 {
  width: 37vw;
  font-size: 3.864734299516908vw;
  margin-bottom: 0vw;
  font-weight: 500;
  color: #a6a6a6;
}

.Bloquetres {
  width: 100%;
  height: 280.68599033816426vw;
  background-color: #f5f9fb;
  padding-left: 14.734299516908212vw;
  padding-top: 16.666666666666664vw;
  background-image: url("../assets/Rectangle28P.png");
  background-repeat: no-repeat;
  background-position: bottom;
}

.B3F1 h6 {
  color: #6fba91;
  font-weight: 500;
  font-size: 3.3816425120772946vw;
}

.B3F1 h2 {
  width: 48vw;
  height: 26vw;
  color: #565656;
  font-weight: 800;
  font-size: 8.454106280193237vw;
}

.GreyLine {
  color: #f5f9fb;
  height: 5vw;
}

.B3F2C1 h3 {
  height: 8vw;
  color: #548eba;
  font-size: 5.797101449275362vw;
  font-weight: 800;
}

.B3F2C1 p {
  width: 62vw;
  color: #a6a6a6;
  font-size: 3.140096618357488vw;
  line-height: 5.746376811594202vw;
}

.B3F2C2 h3 {
  height: 8vw;
  color: #548eba;
  font-size: 5.797101449275362vw;
  font-weight: 800;
}

.B3F2C2 p {
  width: 62vw;
  color: #a6a6a6;
  font-size: 3.140096618357488vw;
  line-height: 5.746376811594202vw;
}

.B3F2C3 h3 {
  height: 8vw;
  color: #548eba;
  font-size: 5.797101449275362vw;
  font-weight: 800;
}

.B3F2C3 ul {
  width: 76vw;
  color: #a6a6a6;
  font-size: 3.140096618357488vw;
  line-height: 5.746376811594202vw;
  columns: 2;
}

.B4C1 {
  width: 100%;
  height: 82.3671497584541vw;
  background-color: #6fba91;
  padding-top: 14.009661835748794vw;
  padding-left: 16.183574879227052vw;
}

.B4C1 h6 {
  color: #0a4ca1;
  font-size: 3.3816425120772946vw;
  font-weight: 500;
}

.B4C1 h3 {
  width: 51vw;
  font-size: 8.454106280193237vw;
  color: white;
  font-weight: 800;
  line-height: 10.144927536231885vw;
}

.B4C1F3 {
  display: flex;
  margin-bottom: 5vw;
}

.B4C1F3 img {
  width: 10.628019323671497vw;
  height: auto;
  margin-right: 8.212560386473431vw;
}

.B4C1F3 p {
  width: 47vw;
  color: white;
  font-size: 3.864734299516908vw;
  line-height: 5.024154589371981vw;
  font-weight: 700;
}

.B4C2 img {
  width: auto;
  height: 71vw;
}

.Bloque12 {
  width: 100%;
  height: 280.19323671497585vw;
  background-color: #deefff;
  text-align: -webkit-center;
  padding-top: 14.734299516908212vw;
}

.B12B1 {
  display: none;
}

.B12_F1 {
  width: 62vw;
  height: 30vw;
  text-align: left;
  margin-bottom: 6.763285024154589vw;
  margin-left: -7vw;
}

.B12_F1 p {
  font-size: 8.454106280193237vw;
  line-height: 10.144927536231885vw;
  font-weight: 800;
  color: #0a4ca1;
}

.B12_F2 {
  width: 80vw;
  height: 24vw;
  text-align: left;
  margin-bottom: 6.521739130434782vw;
}

.B12_F2 label {
  color: #0a4ca1;
  font-size: 3.864734299516908vw;
  line-height: 4.63768115942029vw;
  font-weight: 800;
  margin-bottom: 4vw;
  padding-left: 5.5vw;
}

.B12_F2 input {
  width: 79.95169082125604vw;
  height: 14.734299516908212vw;
  border: 1px solid #0a4ca1;
  border-radius: 8vw;
  background: transparent;
  padding: 0vw 6vw;
}

.B12_F5 {
  width: 80vw;
  height: 55vw;
  text-align: left;
  margin-bottom: 6.521739130434782vw;
}

.B12_F5 label {
  color: #0a4ca1;
  font-size: 3.864734299516908vw;
  line-height: 4.63768115942029vw;
  font-weight: 800;
  margin-bottom: 4vw;
  padding-left: 5.5vw;
}

.B12_F5 textarea {
  width: 79.95169082125604vw;
  height: 46.13526570048309vw;
  border: 1px solid #0a4ca1;
  border-radius: 8vw;
  background: transparent;
  padding: 3vw 6vw;
  resize: none;
}

.B12_F6 {
  margin-top: 2vw;
}

.B12_F6 button {
  width: 79.95169082125604vw;
  height: 14.734299516908212vw;
  color: white;
  border-radius: 8vw;
  border: 0;
  background: linear-gradient(to left, #6fba91 100%, #76dfa6 100%);
  font-weight: 800;
}

.B12_F7 {
  display: block;
  width: 61vw;
  height: 23vw;
  text-align: left;
  margin-left: -7vw;
  margin-top: 9.903381642512077vw;
}

.EB12_F7 {
  display: none;
}

.B12_F7 p {
  font-size: 2.898550724637681vw;
}

.B12_F7 span {
  color: #0a4ca1;
  font-weight: 800;
}

.Bloque13 {
  display: block;
  width: 100%;
  height: 82.85024154589372vw;
  background-image: url("../assets/Rectangle_25P.png");
}

.Bloque14 iframe {
  width: 100%;
  height: 74.63768115942028vw;
}

.B4C1 a {
  color: white;
  background: #0a4ca1;
  font-size: 3.140096618357488vw;
  border-radius: 8vw;
  padding: 5.1vw 26.8vw;
}

@media (min-width: 768px) {
  .ClienteButton {
    margin: 0 0 0 0.5208333333333333vw;
  }

  .ClienteButton a {
    background-color: white;
    color: #0a4ca1 !important;
    font-weight: 900;
    font-size: 0.6770833333333334vw;
    border-radius: 5vw;
    padding: 0.4vw 1vw;
    text-decoration: none;
  }

  .ClienteButton img {
    width: 0.8vw;
    height: auto;
    margin-left: 0.5vw;
    padding-bottom: 0.15vw;
  }

  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100% !important;
    position: relative;
    transition-property: transform;
  }

  .modalss {
    position: fixed;
    z-index: 1;
    padding-top: 10.5vw;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  }

  .B4C1 a {
    color: white;
    background: #0a4ca1;
    font-size: 1vw;
    border-radius: 8vw;
    padding: 1.1vw 3.8vw;
  }

  .Bloque1 {
    background: linear-gradient(243.78deg, #6fba91 17.49%, #0a4ca1 99.32%);
    display: block;
    height: 33.90625vw;
    width: 100%;
    padding-top: 0vw;
  }

  .B1F1 {
    display: flex;
    place-content: center;
    margin: 0vw 6vw;
    padding-bottom: 1vw;
    border-bottom: 1px solid white;
    position: absolute;
  }

  .B1F1C1 {
    margin-right: 24vw;
  }

  .B1F1C1_C1 {
    margin-top: 0.1vw;
    margin-right: 1.6145833333333335vw;
  }

  .B1F1C1_C1 img {
    width: 4.53125vw;
    height: auto;
  }

  .B1F1C1_C2 {
    width: 8vw;
    height: 1.5vw;
    padding-top: 0.5vw;
    text-transform: uppercase;
    margin-left: 0vw;
  }

  .B1F1C1_C2 p {
    font-size: 0.78125vw;
    line-height: 0.9208333333333334vw;
    text-align: left;
  }

  .B1F1C2 {
    display: flex;
    align-items: center;
    z-index: 2;
  }

  .B1F1C2_C1 ul {
    display: flex;
    color: white;
    list-style: none;
    font-weight: 500;
    font-size: 0.6770833333333334vw;
    margin-bottom: 0vw;
  }

  .B1F1C2_C1>ul>li {
    cursor: pointer;
    align-self: center;
  }

  .B1F1C2_C1>ul>li:first-of-type {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .B1F1C2_C1>ul>li:first-of-type:hover {
    text-decoration: underline;
  }

  .B1F1C2_C1>ul>li:not(:last-of-type) {
    width: 5.989583333333334vw;
    height: 1.8229166666666667vw;
  }

  .B1F1C2_C1>ul>li a {
    color: white;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .B1F2 {
    display: flex;
    margin-top: 7.1vw;
  }

  .B1F2C1 {
    width: 52vw;
    height: 10vw;
    margin-top: 4.739583vw;
  }

  .B1F2C1_F1 {
    margin-left: 13vw;
  }

  .B1F2C1_F1 p {
    color: #bbefe3;
    font-weight: 800;
    font-size: 5.208333333333334vw;
    line-height: 3vw;
    margin-bottom: 1.4vw;
    text-align: left;
  }

  .B1F2C1_F1 span {
    font-size: 2.083333333333333vw;
  }

  .B1F2C1_F1S2 {
    margin-left: 13vw;
  }

  .B1F2C1_F1S2 p {
    width: 30vw;
    color: #bbefe3;
    font-weight: 800;
    font-size: 5.208333333333334vw;
    line-height: 3.6vw;
    margin-left: -9vw;
    margin-bottom: 1.4vw;
    text-align: left;
  }

  .B1F2C1_F1S2 span {
    font-size: 2.083333333333333vw;
  }

  .B1F2C1_F2 {
    width: 20vw;
    text-align: left;
    margin-left: -6vw;
  }

  .B1F2C1_F2 p {
    width: 20vw;
    color: white;
    font-weight: 400;
    font-size: 0.78125vw;
    line-height: 1.1213541666666667vw;
    margin-bottom: 3vw;
  }

  .B1F2C1_F2S2 {
    width: 20vw;
    text-align: left;
    margin-left: -6vw;
  }

  .B1F2C1_F2S2 p {
    width: 22vw;
    color: white;
    font-weight: 400;
    font-size: 0.78125vw;
    line-height: 1.1213541666666667vw;
    margin-bottom: 3vw;
  }

  .B1F2C1 a {
    font-size: 0.6770833333333334vw;
    color: white;
    padding: 1.1vw 3.9vw;
    background: linear-gradient(to left, #6fba91 100%, #76dfa6 100%);
    border-radius: 8vw;
    margin-left: -16vw;
    cursor: pointer;
  }

  .B1F2C2 {
    width: 35.041667vw !important;
    height: auto !important;
    margin-left: 0vw;
    margin-bottom: 5vw;
    margin-top: -2.7vw !important;
  }

  .B1F2C2_S2 {
    width: 44.2vw !important;
    height: 29.6vw !important;
    margin-left: -10vw;
    margin-bottom: 5vw;
    margin-top: -2.78vw !important;
    transform: scale(0.8, 1);
  }

  .bloquedos {
    background-image: url("../assets/shutterstock.png");
    background-repeat: no-repeat;
    background-position-y: 5vw;
    background-position-x: 25vw;
    background-size: 54vw;
    height: 45.15625vw;
  }

  .filados-bd {
    display: flex;
    width: 90vw;
    place-content: space-between;
    padding-top: 3.125vw;
  }

  .coluno-bd {
    margin-left: 0vw;
  }

  .bloserv {
    display: flex;
    margin-bottom: 5.46875vw;
  }

  .filauno-bd {
    margin-left: 11.510416666666666vw;
    padding-top: 5.625vw;
  }

  .filauno-bd h6 {
    height: 1vw;
    font-size: 0.8333333333333334vw;
    color: #6fba91;
    font-weight: 500;
    line-height: 1vw;
  }

  .filauno-bd h2 {
    width: 22vw;
    height: 8vw;
    font-size: 3.6458333333333335vw;
    color: #565656;
    font-weight: 800;
    line-height: 4.375vw;
  }

  .blotxtserv {
    margin-left: 1.8229166666666667vw;
    padding-top: 1.1vw;
  }

  .C1IMG1 {
    width: 4.583333333333333vw;
    height: 4.84375vw;
    margin-right: 0vw;
  }

  .C1T1 {
    width: 17vw;
    color: #a6a6a6;
    font-size: 1.0416666666666665vw;
    margin-bottom: 1vw;
  }

  .blotxtserv a {
    font-size: 0.6770833333333334vw;
    color: #9bd2c5;
    font-weight: 500;
  }

  .C1IMG2 {
    width: 4.895833333333333vw;
    height: 4.479166666666667vw;
    margin-right: 0vw;
  }

  .C1T2 {
    width: 5vw;
    color: #a6a6a6;
    font-size: 1.0416666666666665vw;
    margin-bottom: 0vw;
  }

  .C2IMG1 {
    width: 3.28125vw;
    height: 3.854166666666667vw;
    margin-right: 0vw;
  }

  .C2T1 {
    width: 11vw;
    color: #a6a6a6;
    font-size: 1.0416666666666665vw;
    margin-bottom: 0vw;
  }

  .C2IMG2 {
    width: 4vw;
    height: 4.84375vw;
    margin-right: 0vw;
  }

  .C2T2 {
    width: 13vw;
    color: #a6a6a6;
    font-size: 1.0416666666666665vw;
    margin-bottom: 0vw;
  }

  .Bloquetres {
    width: 100%;
    height: 49.375vw;
    padding-top: 7.395833333333333vw;
    padding-left: 0vw;
    background-image: url("../assets/Rectangle_23.png"),
      url("../assets/fondomision_1.png");
    background-repeat: no-repeat;
    background-size: 65vw, 85vw 65vw;
    background-position: left, right;
    background-repeat: no-repeat, no-repeat;
  }

  .B3F1 {
    margin-left: 26.041666666666668vw;
  }

  .B3F1 h6 {
    height: 1vw;
    font-size: 0.8333333333333334vw;
    color: #6fba91;
    font-weight: 500;
    line-height: 1vw;
  }

  .B3F1 h2 {
    width: 34vw;
    height: 8vw;
    font-size: 3.6458333333333335vw;
    color: #565656;
    font-weight: 800;
    line-height: 4.375vw;
  }

  .B3F2 {
    display: flex;
    margin-left: 26.041666666666668vw;
  }

  .GreyLine {
    width: 0.01vw;
    height: 5vw;
    background-color: #a6a6a6;
    margin: 4.3vw 4.6875vw;
  }

  .B3F2C1 h3 {
    height: 3vw;
    color: #548eba;
    font-size: 1.5625vw;
    font-weight: 800;
  }

  .B3F2C1 p {
    width: 16vw;
    color: #a6a6a6;
    font-size: 0.78125vw;
    font-weight: 400;
    line-height: 1.4296874999999998vw;
  }

  .B3F2C2 h3 {
    height: 3vw;
    color: #548eba;
    font-size: 1.5625vw;
    font-weight: 800;
  }

  .B3F2C2 p {
    width: 13.6vw;
    color: #a6a6a6;
    font-size: 0.78125vw;
    font-weight: 400;
    line-height: 1.4296874999999998vw;
  }

  .B3F2C3 h3 {
    height: 3vw;
    color: #548eba;
    font-size: 1.5625vw;
    font-weight: 800;
  }

  .B3F2C3 ul {
    width: 13.6vw;
    color: #a6a6a6;
    font-size: 0.78125vw;
    font-weight: 400;
    line-height: 1.4296874999999998vw;
    padding-left: 1vw;
    columns: 1;
  }

  .Bloquecuatro {
    display: flex;
  }

  .B4C1 {
    width: 46.875vw;
    height: 37.8125vw;
    background-color: #6fba91;
    padding-top: 9.635416666666668vw;
    padding-left: 11.197916666666668vw;
  }

  .B4C1 h6 {
    font-size: 0.8333333333333334vw;
    color: #0a4ca1;
    font-weight: 500;
    margin-bottom: 2.604166666666667vw;
  }

  .B4C1 h3 {
    width: 26vw;
    color: white;
    font-weight: 800;
    font-size: 2.864583333333333vw;
    line-height: 3.4375000000000004vw;
    margin-bottom: 3.125vw;
  }

  .B4C2 img {
    width: 51.9vw;
    height: 37.8vw;
  }

  .B4C1F3 {
    display: flex;
    margin-bottom: 2vw;
  }

  .B4C1F3 img {
    width: 3.0208333333333335vw;
    height: 4.479166666666667vw;
    margin-right: 1.8973958333333332vw;
  }

  .B4C1F3 p {
    width: 15vw;
    color: white;
    font-weight: 700;
    font-size: 1.0416666666666665vw;
    line-height: 1.3541666666666667vw;
  }

  .Bloque12 {
    width: 100%;
    height: 53.697916666666664vw;
    padding-top: 0vw;
    display: flex;
  }

  .B12B1 {
    display: block;
  }

  .B12B1 img {
    width: 46.875vw;
    height: 53.697916666666664vw;
  }

  .B12_Esp {
    padding-left: 8.229166666666666vw;
    padding-top: 4.09375vw;
  }

  .B12_F1 {
    width: 28vw;
    height: 10vw;
    margin-bottom: 2vw;
    margin-right: -8vw;
    margin-left: -19vw;
  }

  .B12_F1 p {
    font-size: 2.604166666666667vw;
    line-height: 3.125vw;
  }

  .B12_F2 {
    width: 13vw;
    height: 6vw;
    margin-bottom: 0vw;
    display: inline-grid;
  }

  .B12_F2 label {
    font-size: 0.8333333333333334vw;
    line-height: 1vw;
    margin-bottom: 0.9vw;
    padding-left: 0vw;
    align-self: center;
  }

  .B12_F2 input {
    width: 11.458333333333332vw;
    height: 3.177083333333333vw;
    border-radius: 8vw;
    padding: 0vw 1.3vw;
  }

  .B12_F5 {
    width: 37vw;
    height: 17vw;
    text-align: left;
    margin-bottom: 2vw;
    padding-top: 2.864583333333333vw;
    margin-left: -2vw;
  }

  .B12_F5 label {
    font-size: 0.8333333333333334vw;
    line-height: 1vw;
    margin-bottom: 0.9vw;
    padding-left: 0vw;
  }

  .B12_F5 textarea {
    width: 36.5625vw;
    height: 7.03125vw;
    border-radius: 1.5625vw;
    background-color: #deefff;
    border-color: #0a4ca1;
    padding: 0.5vw 1.3vw;
  }

  .B12_F6 button {
    width: 36.822916666666664vw;
    height: 4.739583333333333vw;
    border-radius: 8vw;
    margin-left: -2vw;
  }

  .B12_F7 {
    display: none;
  }

  .EB12_F7 {
    display: block;
    width: 32vw;
    height: 5vw;
    text-align: left;
    margin-top: 1.903382vw;
    margin-left: -7vw;
  }

  .Eb12_F1 {
    display: flex;
  }

  .EB12_F7 p {
    font-size: 0.7291666666666666vw;
    margin-right: 1vw;
  }

  .EB12_F7 span {
    color: #0a4ca1;
    font-weight: 800;
  }

  .Bloque13 {
    display: none;
  }

  .Bloque14 iframe {
    width: 100%;
    height: 21.145833333333332vw;
  }

  .B1F1C2_C1 ul>li {
    position: relative;
  }

  .wrapper {
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.3s linear;
    position: absolute;
    background-image: linear-gradient(0deg, #183B68 81.32%, rgba(48, 117, 206, 0.00) 100%);

    width: 20.885416666666668vw;
    border-radius: 0 0 1.0416666666666665vw 1.0416666666666665vw;
    top: 100%;
    left: -8.854166666666668vw;
  }

  .wrapper.extended {
    grid-template-rows: 1fr;
  }

  .wrapper * {
    box-sizing: border-box;
  }

  .wrapper>div {
    overflow: hidden;
    padding: 0 2.1875vw;
  }

  .wrapper h2 {
    color: #BBEFE3;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    text-align: start;

    font-size: 1.25vw;
    line-height: 100%;
    margin: 5vw 0 0.8333333333333334vw;
  }

  .wrapper h2+a {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(0deg, #4F8B77 0%, #6FBA91 100%);
    color: #FFF;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;

    font-size: 0.9895833333333333vw;
    line-height: normal;
    width: 100%;
    height: 2.7604166666666665vw !important;
    border-radius: 2.604166666666667vw;
    margin: 0 0 1.1979166666666667vw;


    width: 100%;
    height: 2.7604166666666665vw !important;
    border-radius: 2.604166666666667vw;
    margin: 0 0 1.1979166666666667vw;
  }

  .wrapper ol {
    padding: 0 0 0 1.0416666666666665vw;
  }

  .wrapper li {
    margin: 0;
    text-align: start;
    color: #BBEFE3;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;

    font-size: 0.8333333333333334vw;
    line-height: 150%;
  }

  .wrapper li a {
    color: #BBEFE3;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    display: inline !important;
    font-size: 0.8333333333333334vw;
    line-height: 1.25vw;
  }

  .wrapper h3 {
    color: #BBEFE3;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    text-align: start;

    font-size: 0.8333333333333334vw;
    line-height: 1.25vw;
    margin: 1.6666666666666667vw 0 1.5625vw;
  }

  .wrapper button {
    border: 0;
    padding: 0;
    background-color: transparent;
    color: #6FBA91;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;

    font-size: 0.7291666666666666vw;
    line-height: 171.429%;
    text-decoration-line: underline;
    margin: 0 0 0.9895833333333333vw;
  }

}
</style>